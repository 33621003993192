import * as actionTypes from "./actionTypes";
export const getMyInvestmentsStart = (data) => {
	return {
		type: actionTypes.GET_MY_INVESTMENTS_START,
		data,
	};
};

export const getMyInvestments = (currencyId, pageLimit) => {
	return {
		type: actionTypes.GET_MY_INVESTMENTS,
		currencyId,
		pageLimit,
	};
};

export const getMyInvestmentsSuccess = (response) => {
	return {
		type: actionTypes.GET_MY_INVESTMENTS_SUCCESS,
		response,
	};
};

export const getMyInvestmentsFail = (error) => {
	return {
		type: actionTypes.GET_MY_INVESTMENTS_FAIL,
		error,
	};
};

export const getInvestmentsChartData = (currencyId) => {
	return {
		type: actionTypes.GET_INVESTMENTS_CHART_DATA,
		currencyId,
	};
};

export const getInvestmentsChartDataSuccess = (response) => {
	return {
		type: actionTypes.GET_INVESTMENTS_CHART_DATA_SUCCESS,
		response,
	};
};
export const getInvestmentsColumnChartData = (currencyId, transactionYear) => {
	return {
		type: actionTypes.GET_INVESTMENTS_COLUMN_CHART_DATA,
		currencyId,
		transactionYear,
	};
};

export const getInvestmentsColumnChartDataSuccess = (response) => {
	return {
		type: actionTypes.GET_INVESTMENTS_COLUMN_CHART_DATA_SUCCESS,
		response,
	};
};

export const getMyInvestmentsGraph = (response) => {
	return {
		type: actionTypes.GET_MY_INVESTMENTS_GRAPH_SUCCESS,
		response,
	};
};

export const getInvestmentUniqueCurrencies = (response) => {
	return {
		type: actionTypes.GET_USER_INVESTMENT_UNIQUE_CURRENCIES,
	};
};

export const getInvestmentUniqueCurrenciesSuccess = (response) => {
	return {
		type: actionTypes.GET_USER_INVESTMENT_UNIQUE_CURRENCIES_SUCCESS,
		response,
	};
};

/* investment full detail */
export const getInvestmentFullDetail = (id) => {
	return {
		type: actionTypes.GET_USER_INVESTMENT_FULL_DETAIL,
		id,
	};
};

export const getInvestmentFullDetailSuccess = (response) => {
	return {
		type: actionTypes.GET_USER_INVESTMENT_FULL_DETAIL_SUCCESS,
		response,
	};
};

export const getInvestmentFullDetailFail = (response) => {
	return {
		type: actionTypes.GET_USER_INVESTMENT_FULL_DETAIL_FAIL,
		response,
	};
};

export const clearInvestmentFullDetail = () => {
	return {
		type: actionTypes.CLEAR_USER_INVESTMENT_FULL_DETAIL,
	};
};

/* investment full detail documents */
export const getInvestmentDocumentDetail = (id) => {
	return {
		type: actionTypes.GET_USER_INVESTMENT_DOCUMENT_DETAIL,
		id,
	};
};

export const getInvestmentDocumentDetailSuccess = (response) => {
	return {
		type: actionTypes.GET_USER_INVESTMENT_DOCUMENT_DETAIL_SUCCESS,
		response,
	};
};

export const getInvestmentDocumentDetailFail = (response) => {
	return {
		type: actionTypes.GET_USER_INVESTMENT_DOCUMENT_DETAIL_FAIL,
		response,
	};
};

export const clearInvestmentDocumentDetail = () => {
	return {
		type: actionTypes.CLEAR_USER_INVESTMENT_DOCUMENT_DETAIL,
	};
};

export const setInvestmentSelectedCurrency = (currency) => {
	return {
		type: actionTypes.SET_INVESMENT_SELECTED_CURRENCY,
		currency,
	};
};

export const getResignContractList = (id) => {
	return {
		type: actionTypes.GET_RESIGN_CONTRACT_LIST,
		id,
	};
};

export const getResignContractSuccess = (response) => {
	return {
		type: actionTypes.GET_RESIGN_CONTRACT_LIST_SUCCESS,
		response,
	};
};
