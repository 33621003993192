const AppPaths = {
	HOME_PATH: "/",
	AUTHENTICATED_HOMEPAGE: "/home",
	LOGIN_PATH: "/login",
	SIGNUP_PATH: "/signup",
	LOGOUT_PATH: "/logout",
	FORGOT_PASSWORD_PATH: "/forgot-password",
	BROWSE_PATH: "/browse-companies",
	BROWSE_SUCCESS_CAMPAIGN_PATH: "/browse-succeess-campaign",
	RAISE_CAPITAL_PATH: "/raise-capital",
	FAQ_PATH: "/faq",
	DASHBOARD_PATH: "/dashboard",
	CAMPAIGNDETAILS_PATH: "/atmos-home",
	CHOOSETYPE_PATH: "/create-campaign/choose-type",
	PROFILE_PATH: "/profile",
	PROFILE_SETTINGS_PATH: "/settings/profile",
	TWO_FACTOR_SETTINGS_PATH: "/settings/two-factor-authentication",
	EMAIL_PREFERENCES_PATH: "/settings/email-preferences",
	CHANGE_PASSWORD_PATH: "/settings/change-password",
	OUTSIDE_LINKS_PATH: "/settings/outside-links",
	INVESTMENTACCOUNT_PATH: "/settings/investment-account", // Investment Account Setting
	PAYMENTOPTIONS_PATH: "/settings/payment-options",
	INBOX_PATH: "/inbox",
	NOTIFICATION_PATH: "/notifications",
	INVITE_PATH: "/invite",
	INVESTMENTS_PATH: "/investments", // My Investment
	INVEST_PATH: "/invest/:id", // Invest Now
	PAYMENT_PATH: "/payment/:id",
	RECEIPT_PATH: "/receipt/:id",
	SUCCESS_PAYMENT_RECEIPT_PATH: "/payment-receipt/:id",
	COMPANYINFO_PATH: "/create-campaign/company-info/:id",
	FUNDRAISING_DETAIL_PATH: "/create-campaign/fundraising-detail/:id",
	STORY_PATH: "/create-campaign/story/:id",
	FAQS_PATH: "/create-campaign/faqs/:id",
	EXTRAS_PATH: "/create-campaign/extras/:id",
	FUNDING_PATH: "/create-campaign/funding/:id",
	THANK_YOU_PATH: "/create-campaign/thank-you/:id",
	CAMPAIGN_DASHBOARD_PATH: "/campaign-dashboard/:id",
	REPAYMENTTAB_PATH: "/campaign-dashboard/repayment/:id",
	REPAYMENT_PATH: "/campaign-dashboard/payment/:id",
	REPAYMENT_RECEIPT_PATH: "/campaign-dashboard/receipt/:id",
	SAVED_CAMPAIGN_PATH: "/saved-campaign",
	RESET_PASSWORD_PATH: "/reset-password",
	CONTENT_PAGE: "/content-page/:contentpage",
	PROJECT_GUIDELINES: "/project-guidelines",
	CAMPAIGN_DETAIL_PAGE: "/campaign-detail-page/:slug",
	INVESTMENTS_FAIL: "/receipt-fail",
	HELP_CENTER_PATH: "/help-center",
	QUESTION_LIST_PATH: "/help-center/question-list",
	SINGLE_QUESTION_PATH: "/help-center/single-question",
	EMAIL_VERIFICATION: "/users/activate-account/:id",
	TERMS_OF_USE: "/terms",
	ACTIVATE_ACCOUNT: "/activate-account/:userId/:token/:authType",
	CONTACT_US_PATH: "/contact-us",
	USER_KYC_PATH: "/user-kyc",
	TWO_FACTOR_AUTHENTICATION_PATH: "/auth-user-verification",
	LOGIN_TWO_FACTOR_AUTHENTICATION_PATH: "/login-user-verification",
	STRIPE_ACCOUNT_CONNECTED_PATH: "/stripe-account-connect",
	STRIPE_ACH_ACCOUNTS_LIST_PATH: "/settings/ach-account-list",
	RESEND_VERIFICATION_EMAIL_PATH: "/resend-email-verification",
	TOP_UP_PAYMENT_PATH: "/topup-payment/:id",
	TOP_UP_RECEIPT_PATH: "/topup-receipt/:id",
	TOP_UP_RECEIPT_FAIL_PATH: "/topup-fail",
	WALLET_PATH: "/settings/wallet",
	WALLET_PAYMENT_PATH: "/wallet-payment/:id",
	PAYMENT_RECEIPT_PATH: "/paymob-payment-confirmation",
	ACCOUNT_KYC_VERIFICATION_PATH: "/account-kyc-verification",
	ACCOUNT_KYC_PERSONAL_DETAILS_PATH: "/account-verification-personal-detail",
	ACCOUNT_KYC_DECLARATIONS_PATH: "/account-verification-declarations",
	ACCOUNT_KYC_SUITABILITY_TEST_PATH: "/account-verification-suitability-test",
	ACCOUNT_KYC_SCAN_VERIFY_PATH: "/account-verification-scan-and-verify",
	ACCOUNT_OTP_VERIFY_PATH: "/otp-verification",
	CHILDREN_REGISTRATION_PATH: "/children-registration",
	UPDATE_CHILDREN_REGISTRATION_PATH: "/children-registration/:id",
	CHILDREN_LIST_PATH: "/children-list",
	CHILD_INVESTMENT_DASHBOARD_PATH: "/child-investments/:id",
};

export default AppPaths;
