export const ADD_WALLET_TOP_UP = "ADD_WALLET_TOP_UP";
export const CREATE_WALLET_OFFLINE_PAYMENT = "CREATE_WALLET_OFFLINE_PAYMENT";
export const CREATE_WALLET_OFFLINE_PAYMENT_SUCCESS =
	"CREATE_WALLET_OFFLINE_PAYMENT_SUCCESS";
export const ADD_WALLET_TOP_UP_SUCCESS = "ADD_WALLET_TOP_UP_SUCCESS";
export const GET_WALLET_DETAILS = "GET_WALLET_DETAILS";
export const GET_WALLET_DETAILS_SUCCESS = "GET_WALLET_DETAILS_SUCCESS";
export const GET_WALLET_PREAPPROVAL_DETAILS = "GET_WALLET_PREAPPROVAL_DETAILS";
export const GET_WALLET_PREAPPROVAL_DETAILS_SUCCESS =
	"GET_WALLET_PREAPPROVAL_DETAILS_SUCCESS";
export const GET_WALLET_TRANSACTIONS = "GET_WALLET_TRANSACTIONS";
export const GET_WALLET_TRANSACTIONS_SUCCESS =
	"GET_WALLET_TRANSACTIONS_SUCCESS";
export const POST_WALLET_ACH_PAYMENT = "POST_WALLET_ACH_PAYMENT";
export const POST_WALLET_ACH_PAYMENT_SUCCESS =
	"POST_WALLET_ACH_PAYMENT_SUCCESS";
export const RESET_TOPUP_PAYMENT_DATA = "RESET_TOPUP_PAYMENT_DATA";
export const SET_WALLET_SELECTED_CURRENCY = "SET_WALLET_SELECTED_CURRENCY";
export const SET_SELECTED_CAMPAIGN_ID = "SET_SELECTED_CAMPAIGN_ID";
export const UPDATE_WALLET_TOPUP = "UPDATE_WALLET_TOPUP";
export const UPDATE_WALLET_TOPUP_SUCCESS = "UPDATE_WALLET_TOPUP_SUCCESS";
export const WALLET_FAIL = "WALLET_FAIL";
export const WALLET_START = "WALLET_START";
export const WALLET_PAYMENT_START = "WALLET_PAYMENT_START";
export const WALLET_STRIPE_PAYMENT_INITIATE = "WALLET_STRIPE_PAYMENT_INITIATE";
export const WALLET_STRIPE_PAYMENT_INITIATE_SUCCESS =
	"WALLET_STRIPE_PAYMENT_INITIATE_SUCCESS";
export const WITHDRAW_FROM_WALLET = "WITHDRAW_FROM_WALLET";

// Child Regisstration Wallet List
export const GET_CHILD_WALLET_LIST = "GET_CHILD_WALLET_LIST";
export const GET_CHILD_WALLET_LIST_SUCCESS = "GET_CHILD_WALLET_LIST_SUCCESS";
export const GET_CHILD_WALLET_TRANSACTIONS = "GET_CHILD_WALLET_TRANSACTIONS";
export const GET_CHILD_WALLET_TRANSACTIONS_SUCCESS =
	"GET_CHILD_WALLET_TRANSACTIONS_SUCCESS";

export const ADD_CHILD_WALLET_TOP_UP = "ADD_CHILD_WALLET_TOP_UP";
export const ADD_CHILD_WALLET_TOP_UP_SUCCESS =
	"ADD_CHILD_WALLET_TOP_UP_SUCCESS";
export const CHILD_WITHDRAW_FROM_WALLET = "CHILD_WITHDRAW_FROM_WALLET";

export const GET_CHILD_WALLET_DETAILS = "GET_CHILD_WALLET_DETAILS";
export const GET_CHILD_WALLET_DETAILS_SUCCESS =
	"GET_CHILD_WALLET_DETAILS_SUCCESS";
